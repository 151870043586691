import { Blockquote, Amazon, Series, Youtube, Twitter, InternalLink } from '../../../../src/components/blog';
import * as React from 'react';
export default {
  Blockquote,
  Amazon,
  Series,
  Youtube,
  Twitter,
  InternalLink,
  React
};