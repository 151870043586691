import { Blockquote, Amazon, Series, InternalLink, Twitter, Button } from '../../../../src/components/blog';
import * as React from 'react';
export default {
  Blockquote,
  Amazon,
  Series,
  InternalLink,
  Twitter,
  Button,
  React
};