import { Blockquote, Amazon, Vocabulary, FatalShadowsIndex, Instagram, AffiliateBanner } from '../../../../src/components/blog';
import * as React from 'react';
export default {
  Blockquote,
  Amazon,
  Vocabulary,
  FatalShadowsIndex,
  Instagram,
  AffiliateBanner,
  React
};