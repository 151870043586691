import { Amazon, Blockquote, Youtube, AffiliateBanner, InternalLink, Button, Announce } from '../../../../src/components/blog';
import * as React from 'react';
export default {
  Amazon,
  Blockquote,
  Youtube,
  AffiliateBanner,
  InternalLink,
  Button,
  Announce,
  React
};